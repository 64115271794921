import React, { createContext } from 'react'
import { graphql } from 'gatsby'

// Components
import SEO from 'components/shared/SEO'
import Layout from 'components/layout/Layout'
import FlexContentHandler from 'components/shared/FlexContentHandler'

export interface ContextProps {
  services: Array<{
    name: string
    description: string
    service: any
    slug: string
  }>
}

export const ServiceContext = createContext<ContextProps>({
  services: [],
})

interface PageTemplateProps {
  data: {
    page: {
      path?: string
      title?: string
      seo: any
      flexContent: any
      services: {
        nodes: []
      }
    }
  }
  location?: any
  prefix?: string
  pageContext?: any
}

const PageTemplate: React.FC<PageTemplateProps> = ({
  data: {
    page: { flexContent, seo, services },
  },
  location = {},
  prefix = 'Case_Flexcontent',
  pageContext,
}) => {
  const previewServices = [
    {
      description: 'Lorem ipsum',
      service: {
        icon: {
          localFile: {
            publicURL:
              '/static/74427bb4996f95cc439481be4a9a2b32/security.inline.svg',
          },
        },
      },
    },
    {
      description: 'Lorem ipsum',
      service: {
        icon: {
          localFile: {
            publicURL:
              '/static/74427bb4996f95cc439481be4a9a2b32/security.inline.svg',
          },
        },
      },
    },
    {
      description: 'Lorem ipsum',
      service: {
        icon: {
          localFile: {
            publicURL:
              '/static/74427bb4996f95cc439481be4a9a2b32/security.inline.svg',
          },
        },
      },
    },
  ]
  return (
    <Layout>
      <SEO seo={seo} />
      <ServiceContext.Provider
        value={{
          services: services ? services.nodes : previewServices,
        }}
      >
        <FlexContentHandler
          prefix={prefix}
          fields={flexContent}
          location={location}
          pageContext={pageContext}
        />
      </ServiceContext.Provider>
    </Layout>
  )
}

export const pageQuery = graphql`
  query caseById($pageId: String!) {
    page: wpCase(id: { eq: $pageId }) {
      ...generalCaseFragment
    }
  }
`

export default PageTemplate
